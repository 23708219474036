import router from "@/router";
import axios from "axios";
import { Message } from "element-ui";



const instance = axios.create({
    //http://192.168.2.22
    // baseURL: 'http://192.168.2.22:8081', //本地
    //baseURL: 'http://localhost:8081', //本地
    // baseURL: 'http://localhost:88/hei.api', //本地 Nginx 反向代理
    //baseURL: 'https://hei.zbm666.com', //线上
    timeout: 180000
})

instance.interceptors.request.use(config => {
    const token = localStorage.getItem('access_token')
    //console.log(token,"1")
    if(token && !config.url.endsWith('/login')){
        //如果有token携带(http协议)
        config.headers["Authorization"] = "Bearer "+token
        //console.log(token,"2")
    }
    return config
},err => {
    return Promise.reject(err)
});

instance.interceptors.response.use(res => {
    //这里可以在接收之前进行一些处理

    let res_data = res.data

    //console.log('请求响应拦截器的',res_data)

    if(res_data.code != 200){
        if(res_data.msg == 'NO_ADDRESS'){
            console.log('NO_ADDRESS')
            router.push("/no")
        }
        if(res_data.msg == 'NO_USER'){
            Message.error('登录失败！您输入的账号或者密码有误')
            return false;
        }
        if(res_data.msg == 'USER_TIMEOUT'){
            Message.error('您的软件账号已到期！')
            return false;
        }
        if(res_data.msg == 'LOGIN_TIMEOUT'){
            Message.error('登录超时，请重新登录！');
            localStorage.clear()
            router.push("/login")
            return false;
        }
        if(res_data.msg == "token过期"){
            localStorage.removeItem('access_token')
            router.push("/login")
            return false;
        }
        if(res_data.msg == 'REFRESH_TK'){
            return false;
        }
        if(res_data.msg == 'PARAMETER_ERROR'){
            Message.error(res_data.data??'请求参数错误');
            return false;
        }
        if(res_data.msg == 'MISSING_PARAMS'){
            Message.error('缺少参数，请刷新后重试');
            return false;
        }
        if(res_data.msg == 'ADMIN_ROLE_AUTH_FAIL'){
            Message.error('亲，您当前无权限使用');
            return false;
        }
        if(res_data.msg === 'CODE_LOGIN_PAGE_EXPIRE'){
            Message.error('页面失效，请刷新页面');
            return false;
        }
        if(res_data.msg === 'IDENTITY_VERIFY_FAIL'){
            Message.error('主播身份校验失败,请检查地址是否正确');
            return false;
        }
        if(res_data.msg === 'CHECK_CODE_ERROR'){
            Message.error('您输入的开播码错误');
            return false;
        }
        if(res_data.msg === 'ADD_SALES_LIBRARY_FAIL'){
            Message.error('添加到销量库失败');
            return false;
        }
        if(res_data.msg === 'ITEM_NOT_EXIST'){
            Message.error('商品不存在');
            return false;
        }
        if(res_data.msg === 'ITEM_EXIST_SELF_LIBRARY'){
            Message.warning('商品已存在自选库中');
            return false;
        }
        if(res_data.msg === 'NOT_ANCHOR_DATA'){
            Message.error(res_data.data??'无该主播数据')
            return false;
        }
        if(res_data.msg === 'ANCHOR_LOGIN_INVALID'){
            Message.error('主播登录失效');
            //主播登录失效
            return false;
        }
        Message.error(res_data.msg || "网络请求错误")
        return false;
    }

    return res.data
},err => {
    return Promise.reject(err)
});

export default instance